(function($) {
  //caches a jQuery object containing the header element
  var header = $(".banner");
  $(window).scroll(function() {
    var scroll = $(window).scrollTop();

    if (scroll >= 200) {
      header.addClass("solid-header");
    } else {
      header.removeClass("solid-header");
    }
  });
})(jQuery);